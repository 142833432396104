import { Fragment } from 'react/jsx-runtime';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';

import MaterialSymbol from 'components/material-symbols';
import Badge from 'components/Badge';

import Row from 'components/layout/row';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import * as css from 'components/formatted-content/embeds/form/styles.css';
import ContentUnavailable from 'components/embeds/ContentUnavailable';

import * as embedCss from '../styles.css';

interface FormCategoryEmbedProps {
	categoryId: number;
	organizationId: number;
}

export default function FormCategoryEmbed({
	categoryId,
	organizationId,
}: FormCategoryEmbedProps) {
	const {
		record: payload,
		isLoading,
		hasError,
	} = useEndpoint<models.formCategory.FormCategory>(
		endpoints.FormCategories.Show(categoryId)
	);

	let content = <Spinner />;

	if (!isLoading && !hasError) {
		content = (
			<Link
				href={routes.Registrations.Category(organizationId, categoryId)}
				className={css.wrapper}>
				<Row columns="40px 1fr auto" align="center">
					<div
						className={css.badge}
						style={{
							backgroundColor: models.group.getPrimaryColorStylesheetString(
								payload.group
							),
						}}>
						<Badge badgeUrl={payload.group.profileImageUrl} />
					</div>
					<div>
						<Column className={css.column}>
							<strong className={css.title}>{payload.title}</strong>
							<span className={css.description}>{payload.description}</span>
						</Column>
					</div>
					<MaterialSymbol variant="chevron_right" actualSize scale={2} />
				</Row>
			</Link>
		);
	}

	if (hasError) {
		return <ContentUnavailable />;
	}

	return (
		<Fragment>
			<div className={embedCss.wrapper}>{content}</div>
		</Fragment>
	);
}
