import { useState } from 'react';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';

import { useInvoiceFilters } from 'routes/payments/orders/filters';

import Pagination from 'components/pagination';

import { TableContentWrapper } from 'components/layout/PageTemplates';
import OrderTable from 'components/orders/table';
import OrdersActionBar from 'components/payment_platform/payments/orders_action_bar';

interface RegistrationOrdersProps {
	formId: number;
}

export default function RegistrationOrders({
	formId,
}: RegistrationOrdersProps) {
	const filters = useInvoiceFilters();

	const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);

	const {
		records: orders,
		pagination,
		sort,
		selection,
		replaceRecord,
		removeRecord,
		selectedRecords,
		refresh,
		replaceMultipleRecords,
	} = useCollection<models.order.Order>(endpoints.Forms.Orders(formId), {
		showOnlySelected,
		queryParams: filters.queryParam,
		useLocationPagination: true,
	});

	return (
		<TableContentWrapper>
			<OrdersActionBar
				filters={filters}
				selectedItems={selection.selectedRecords as number[]}
				showSelected={showOnlySelected}
				setShowSelected={setShowOnlySelected}
				filterParams={filters.queryParam}
				onUpdateRecords={replaceMultipleRecords}
				refresh={refresh}
				orders={orders}
				selectedRecords={selectedRecords}
				hideNewInvoice
				formId={formId}
			/>
			<OrderTable
				sort={sort}
				orders={orders}
				handleSelectAll={selection.selectAll}
				isSelected={selection.isSelected}
				select={selection.selectSingle}
				replaceRecord={replaceRecord}
				removeRecord={removeRecord}
				skipCreateButton
			/>
			<Pagination {...pagination} />
		</TableContentWrapper>
	);
}
