import { t } from '@transifex/native';
import { Fragment } from 'react';

import DateTime from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { toFilterQueryUrl } from 'pkg/filters/use_filters';
import { FilterOperator, createQueryFilter } from 'pkg/filters';
import { useCurrentOrganization } from 'pkg/identity';

import { DateSpanFooter, Widget } from 'routes/payments/overview/widgets';
import { useOrderReport } from 'routes/payments/overview/hooks';

import * as DataCard from 'design/data_card';
import * as Chart from 'design/chart';

import * as css from '../styles.css';

export default function SubscriptionsWidget(): JSX.Element {
	const org = useCurrentOrganization();
	const { subscriptionsGraph } = useOrderReport();

	const {
		total,
		active,
		scheduled,
		pending,
		completed,
		canceled,
		uncollectible,
	} = subscriptionsGraph.statuses;
	const { datePeriods, showAs } = subscriptionsGraph.graphData;

	const getStatusFilter = (
		values: string[],
		operator: FilterOperator = FilterOperator.Includes
	) =>
		createQueryFilter({
			operator,
			values,
		});

	const statusBreakdown: DataCard.BreakdownItem[] = [
		{
			title: t('Active'),
			color: 'green',
			variant: 'label',
			value: active.toString(),
			onClick: () =>
				pushState(
					toFilterQueryUrl(routes.Payments.Subscriptions(org.id), {
						status: getStatusFilter(['active']),
					})
				),
		},
		{
			title: t('Pending'),
			color: 'gray',
			variant: 'label',
			value: pending.toString(),
			onClick: () =>
				pushState(
					toFilterQueryUrl(routes.Payments.Subscriptions(org.id), {
						status: getStatusFilter(['pending']),
					})
				),
		},
		{
			title: t('Scheduled'),
			color: 'blue',
			variant: 'label',
			value: scheduled.toString(),
			onClick: () =>
				pushState(
					toFilterQueryUrl(routes.Payments.Subscriptions(org.id), {
						status: getStatusFilter(['scheduled']),
					})
				),
		},
		{
			title: t('Completed'),
			color: 'purple',
			variant: 'label',
			value: completed.toString(),
			onClick: () =>
				pushState(
					toFilterQueryUrl(routes.Payments.Subscriptions(org.id), {
						status: getStatusFilter(['completed']),
					})
				),
		},
		{
			title: t('Canceled'),
			color: 'orange',
			variant: 'label',
			value: canceled.toString(),
			onClick: () =>
				pushState(
					toFilterQueryUrl(routes.Payments.Subscriptions(org.id), {
						status: getStatusFilter(['canceled']),
					})
				),
		},
		{
			title: t('Uncollectible'),
			color: 'orange',
			variant: 'label',
			value: (uncollectible || 0).toString(),
			onClick: () =>
				pushState(
					toFilterQueryUrl(routes.Payments.Subscriptions(org.id), {
						status: getStatusFilter(['uncollectible']),
					})
				),
		},
	];

	const chartData: Chart.ChartItem[] = Object.entries(datePeriods).map(
		([date, item]) => ({
			title: date,
			value: item.currentPeriod,
		})
	);

	const tooltipFormatter = (item: Chart.ChartItem) => {
		const dateTime = new DateTime(new Date(item.title));
		const formattedDate =
			showAs === 'months'
				? dateTime.toLocaleDateString({ year: 'numeric', month: 'short' })
				: dateTime.toDateString();

		return (
			<Fragment>
				<strong className={css.prominent_tooltip_title}>{item.value}</strong>
				<span>{formattedDate}</span>
			</Fragment>
		);
	};

	return (
		<Widget>
			<DataCard.Base
				titleIcon="sync"
				title={t('Subscriptions')}
				heading={total.toString()}
				footer={<DateSpanFooter granularity={showAs} />}>
				<DataCard.Breakdown items={statusBreakdown} />
				<Chart.Linear
					items={chartData}
					formatTooltip={tooltipFormatter}
					emptyStateLabel={t('No data for selected period')}
				/>
			</DataCard.Base>
		</Widget>
	);
}
