import React, {
	Fragment,
	useState,
	cloneElement,
	useMemo,
	ReactElement,
} from 'react';

import * as styles from 'pkg/config/styles';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import * as LargeLayout from 'components/navigation/header/large_screen/Styles';
import * as SmallLayout from 'components/navigation/header/small_screen/Styles';
import * as SideBarLayout from 'components/layout/SideBarPage';
import { LayoutPortalLocation } from 'components/layout/portal';
import { PageProps } from 'components/layout/PageTemplates';

export function UserProfileLayout({
	title,
	icon,
	subNavItems = [],
	children,
}: PageProps): JSX.Element {
	const [pageTitle, setPageTitle] = useState(title);
	const [pageIcon, setPageIcon] = useState(icon as MaterialSymbolVariant);
	const [pageSubNavItems, setPageSubNavItems] = useState(subNavItems);
	children = useMemo(
		() =>
			React.Children.map(children, (c: ReactElement) =>
				cloneElement(c, {
					setPageTitle,
					setPageIcon,
					setPageSubNavItems,
				})
			),
		[children]
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{pageSubNavItems.map((item, n) => (
						<LargeLayout.SubNavItem key={n} {...item}>
							{item.title}
						</LargeLayout.SubNavItem>
					))}
				</LargeScreenHeader>
				<LargeScreenContent.Wrapper>
					<SideBarLayout.Layout>
						<SideBarLayout.SideBar>
							<LayoutPortalLocation portalId="user-profile-header" />
							<LayoutPortalLocation portalId="user-profile-sidebar" />
						</SideBarLayout.SideBar>
						<SideBarLayout.Content>
							<LargeScreenContent.Inner maxWidth={styles.size.PageWidths.WIDE}>
								{children}
							</LargeScreenContent.Inner>
						</SideBarLayout.Content>
					</SideBarLayout.Layout>
				</LargeScreenContent.Wrapper>
			</LargeScreen>
			<SmallScreen>
				<SmallScreenHeader title={title} />
				<SmallScreenContent backgroundColor={styles.palette.white}>
					<SmallLayout.Top>
						<LayoutPortalLocation portalId="user-profile-header" />
						<SmallLayout.SubNav>
							{pageSubNavItems.map((item, n) => (
								<SmallLayout.SubNavItem key={n} {...item}>
									{item.title}
								</SmallLayout.SubNavItem>
							))}
						</SmallLayout.SubNav>
					</SmallLayout.Top>
					<LargeScreenContent.Inner noPadding>
						<LayoutPortalLocation portalId="user-profile-sidebar" />
						{children}
					</LargeScreenContent.Inner>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
}
