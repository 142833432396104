import { t } from '@transifex/native';

import { Scrubbable } from 'pkg/api/models/scrubbable';
import { isValidBirthDate, yearsSince } from 'pkg/date';
import { Record } from 'pkg/api/models/record';
import { Dateable } from 'pkg/api/models/dateable';
import { UserAddress, UserAddressTypes } from 'pkg/api/models/user_address';
import { UserProduct } from 'pkg/api/models/user_product';
import * as models from 'pkg/api/models';
import { Group } from 'pkg/api/models/group';
import { Membership } from 'pkg/api/models/membership';
import { validateSwedishPersonalId } from 'pkg/strings';
import {
	UserField,
	UserFieldType,
	Visibility,
} from 'pkg/api/models/user_fields';
import { ISO3166Alpha2Codes } from 'pkg/countries';
import { AllowedActionable } from 'pkg/api/models/allowed_actionable';

export enum UserSex {
	Unknown = 0,
	Male = 1,
	Female = 2,
	NotApplicable = 9,
}

export interface Field {
	label: string;
	key: string;
	visibility: Visibility[];
	values?: string[];
	attachments?: models.attachment.Attachment[];
	type: UserFieldType;
}

export enum MetaField {
	Height = 'height',
	MotherHeight = 'mother-height',
	FatherHeight = 'father-height',
	RelativeOneName = 'relative-one-name',
	RelativeOneNumber = 'relative-one-number',
	RelativeOneEmail = 'relative-one-email',
	RelativeTwoName = 'relative-two-name',
	RelativeTwoNumber = 'relative-two-number',
	RelativeTwoEmail = 'relative-two-email',
	Allergies = 'allergies',
	Misc = 'misc',
	LegalGuardian = 'legal-guardian-for',
}

export interface IdentityData extends Dateable, Record {
	identityProviderId: number;
	userId: number;
}

export interface User extends Scrubbable, Record, Dateable, AllowedActionable {
	accountId?: number;
	organizationId: number;
	billingUserId?: number;
	firstName: string;
	lastName?: string;
	companyName?: string;
	email?: string;
	sex?: UserSex;
	address?: string;
	region?: string;
	postalCode?: string;
	city?: string;
	country: string;
	phoneNumber?: string;
	birthDate?: string;
	profileImageUrl?: string;
	deletedAt?: number;
	meta?: any;
	flags?: string[];
	account?: any;
	paymentProviderId?: any;
	taxIdType?: string;
	taxIdValue?: string;
	language?: string;
	languageCode?: string;
	nationality?: string;
	lokSwedishPersonalId?: string;
	lokValid?: boolean;
	identityData?: IdentityData[];

	groups?: Membership[];
	addresses?: UserAddress[];
	billingUser?: User;
	products?: UserProduct[];
	organization?: Group;
	fields?: Field[];
	missingRequiredFields?: UserField[];
	wards?: User[];
}

export interface LoginInformation {
	accountEmail: string;
	usesSignInWithApple: boolean;
}

export function getMeta<T = string>(user: User, key: MetaField): T {
	return user.meta?.find((meta: any) => meta.key === key)?.value as T;
}

export function fullName(user: User): string {
	if (!user) {
		return '';
	}

	return `${user.firstName} ${user.lastName}`;
}

export function initials(user: User): string {
	return `${user.firstName.substring(0, 1)}${user.lastName.substring(0, 1)}`;
}

export function sexString(user: User): string {
	let sexString = 'not_set';

	if (user.sex === UserSex.Male) {
		sexString = 'male';
	} else if (user.sex === UserSex.Female) {
		sexString = 'female';
	} else if (user.sex === UserSex.NotApplicable) {
		sexString = 'not_applicable';
	}

	return sexString;
}

export function translatedSexString(
	sex: UserSex,
	defaultReturn?: string
): string {
	if (sex === UserSex.Unknown) {
		return t('Unknown');
	} else if (sex === UserSex.Male) {
		return t('Male', { _context: 'male gender for teams' });
	} else if (sex === UserSex.Female) {
		return t('Female', { _context: 'female gender for teams' });
	} else if (sex === UserSex.NotApplicable) {
		return t('Not applicable', { _context: 'no applicable genders for teams' });
	}

	return defaultReturn;
}
export function stringToSex(value: string): UserSex | undefined {
	switch (value.toLowerCase()) {
		case 'male':
			return UserSex.Male;
		case 'female':
			return UserSex.Female;
		case 'notApplicable':
			return UserSex.NotApplicable;
		default:
			return undefined;
	}
}

export function age(user: User): number {
	return yearsSince(new Date(user.birthDate));
}

export function isAnAdult(user: User): boolean {
	return age(user) >= 18;
}

export function hasHandicap(user: User): boolean {
	return user.flags.includes('has_handicap');
}

export function getEstimatedHeight(user: User): number {
	const checkFormat = (rawValue: string): number => {
		rawValue = `${Math.round(parseFloat(rawValue))}`;
		if (Number.isNaN(rawValue.charAt(1))) {
			rawValue = rawValue.replace(/\D/g, '');
		}
		return Number.parseInt(rawValue, 10);
	};

	const parents = {
		mother: checkFormat(user.meta['mother-height'] as string),
		father: checkFormat(user.meta['father-height'] as string),
	};

	if (!parents.mother || !parents.father) {
		return 0;
	}

	if (!user.sex || user.sex === UserSex.NotApplicable) {
		return 0;
	}

	return (
		(parents.mother + parents.father + (user.sex === UserSex.Male ? 13 : -13)) /
		2
	);
}

export function height(user: User): string {
	return getMeta(user, MetaField.Height);
}

export function legalGuardianFor(user: User): string {
	return getMeta(user, MetaField.LegalGuardian);
}

export function allergies(user: User): string {
	return getMeta(user, MetaField.Allergies);
}

export function misc(user: User): string {
	return getMeta(user, MetaField.Allergies);
}

export function getBillingEmail(user: User) {
	if (user.billingUser?.id) {
		return user.billingUser.addresses?.length > 0
			? user.billingUser.addresses.filter(
					(a) => a.type === UserAddressTypes.Billing
				)[0].email
			: user.billingUser.email;
	} else {
		return user.addresses?.length > 0
			? user.addresses.filter((a) => a.type === UserAddressTypes.Billing)[0]
					.email
			: user.email;
	}
}

export function getBillingAddress(user: User): UserAddress {
	return user.addresses?.length > 0
		? user.addresses.filter((a) => a.type === UserAddressTypes.Billing)[0]
		: ({
				firstName: user.firstName,
				lastName: user.lastName,
				email: user.email,
				phoneNumber: user.phoneNumber,
				address: user.address,
				city: user.city,
				country: user.country,
				postalCode: user.postalCode,
			} as UserAddress);
}

export function getMissingRequiredUserFields(
	user: models.user.User
): models.userFields.UserField[] {
	return user?.missingRequiredFields || [];
}

export function isMissingRequiredInformation(user: User): boolean {
	return user?.missingRequiredFields?.length > 0;
}

const luhnValid = (luhnNumber: number) => {
	return ((luhnNumber % 10) + checksum(Math.floor(luhnNumber / 10))) % 10 == 0;
};

const checksum = (number: number): number => {
	let luhn: number = 0;

	for (let i = 0; number > 0; i++) {
		let cur = number % 10;

		if (i % 2 === 0) {
			cur = cur * 2;
			if (cur > 9) {
				cur = (cur % 10) + Math.floor(cur / 10);
			}
		}

		luhn += cur;
		number = Math.floor(number / 10);
	}

	return luhn % 10;
};

export function validatePersonalNumber(personalNumber: string) {
	let passedPersonalNumberCheck = true;

	if (!validateSwedishPersonalId(personalNumber)) {
		passedPersonalNumberCheck = false;
	}

	const luhnNumber = Number.parseInt(
		personalNumber.slice(2, 8) + personalNumber.slice(9),
		10
	);
	if (luhnNumber == 0) {
		passedPersonalNumberCheck = false;
	}

	// only years in this range is valid in IdrottOnline
	const year = Number.parseInt(personalNumber.slice(0, 4), 10);
	if (year < 1800 || year > 2100) {
		passedPersonalNumberCheck = false;
	}

	if (!isValidBirthDate(personalNumber.slice(0, 8))) {
		passedPersonalNumberCheck = false;
	}

	if (!luhnValid(luhnNumber)) {
		passedPersonalNumberCheck = false;
	}

	return passedPersonalNumberCheck;
}

export function getFieldValuesAsPayload(fields = [] as unknown) {
	const payloadFields = [];

	for (const [keyWithType, value] of Object.entries(fields)) {
		const [type, key] = keyWithType.split(':');

		switch (type) {
			case models.userFields.UserFieldType.Attachment:
				if (!value) {
					payloadFields.push({
						key,
						value: '',
					});
				} else {
					payloadFields.push({
						key,
						attachmentId: Number.parseInt(value, 10),
					});
				}

				break;
			case models.userFields.UserFieldType.MultiChoice:
				if (!value || value.length === 0) {
					payloadFields.push({
						key,
						value: '',
					});
				} else {
					for (const option of value) {
						payloadFields.push({
							key,
							value: option,
						});
					}
				}
				break;
			default:
				payloadFields.push({
					key,
					value,
				});
				break;
		}
	}

	return payloadFields;
}

export function getCountryString(user: models.user.User): string {
	let countryString = '';

	const countryCode = ISO3166Alpha2Codes().find(
		(c) => c.code === user.country?.toLowerCase()
	)?.country;

	if (user.country && countryCode) {
		countryString = countryCode;
	} else if (user.country) {
		countryString = user.country;
	}

	return countryString;
}
