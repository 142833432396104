import { t } from '@transifex/native';

import {
	ContactsWithUserProductsData,
	ProductStatusData,
} from 'pkg/api/dashboards/management';
import {
	UserProductStatuses,
	getUserProductStatusTranslation,
} from 'pkg/api/models/user_product';
import * as routes from 'pkg/router/routes';
import { pushState } from 'pkg/router/state';
import { toFilterQueryUrl } from 'pkg/filters/use_filters';
import { FilterOperator, createQueryFilter } from 'pkg/filters';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { useCurrentGroup, useCurrentOrganization } from 'pkg/identity';

import * as DataCard from 'design/data_card';

interface ContactsWithUserProductsWidgetProps {
	data: ContactsWithUserProductsData;
}

function getStatusColor(status: UserProductStatuses) {
	switch (status) {
		case UserProductStatuses.Active:
			return 'green';
		case UserProductStatuses.Open:
			return 'blue';
		case UserProductStatuses.PastDue:
			return 'red';
	}
}

export default function ContactsWithUserProductsWidget({
	data,
}: ContactsWithUserProductsWidgetProps) {
	const group = useCurrentGroup();
	const org = useCurrentOrganization();

	const makeFilterUrl = (status: UserProductStatuses) => {
		return toFilterQueryUrl(routes.Management.Contacts.Home(org.id), {
			product_status: createQueryFilter({
				operator: FilterOperator.Includes,
				values: [status],
			}),
		});
	};

	const breakdownItems: DataCard.BreakdownItem[] = Object.entries(
		data.productStatusData
	).map(([key, value]: [UserProductStatuses, ProductStatusData]) => {
		return {
			title: getUserProductStatusTranslation(key),
			value: value.userProductsCount.toString(),
			variant: 'label',
			color: getStatusColor(key),
			onClick: () => pushState(makeFilterUrl(key)),
		} as DataCard.BreakdownItem;
	});

	return (
		<DataCard.Base
			title={t('Contact product statuses')}
			titleIcon="price_change"
			heading={data.totalCount.toString()}
			headingSuffix={formatToCurrency(data.totalAmount / 100, group.currency)}>
			<DataCard.Breakdown items={breakdownItems} />
		</DataCard.Base>
	);
}
