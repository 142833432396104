import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import store from 'pkg/store/createStore';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';
import { link } from 'pkg/router/utils';
import { newChatParams } from 'pkg/chat';

import OverdueUserProductsModal from 'routes/group/show/overdue_products';
import TitleModal from 'routes/group/show/TitleModal';

import Icon from 'components/icon';

import { RemoveUserItem, useRemoveUserDialog } from 'components/user/remove';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

type Modals = '' | 'title' | 'overdue';

interface UserActionsProps {
	userHasOverduePayments: boolean;

	currentMembership: models.membership.Membership;
	membership: models.membership.Membership;
	removeRecord: (id: number) => void;
	replaceRecord: (membership: models.membership.Membership) => void;
	group: models.group.Group;
}

export const UserActions = ({
	userHasOverduePayments,
	currentMembership,
	membership,
	removeRecord,
	replaceRecord,
	group,
}: UserActionsProps): JSX.Element => {
	const org = useCurrentOrganization();
	const isParentForMembership =
		currentMembership?.targetUserId === membership.userId;

	const [modal, setModal] = useState<Modals>('');

	const isSelf = membership.userId === currentMembership.userId;

	const showChatItem = () => {
		if (
			currentMembership.userId === membership.userId ||
			!membership.user.accountId ||
			!models.group.hasFeature(group, models.group.Features.Chat)
		) {
			return false;
		}

		// Admin and staff can chat with everyone
		if (models.membership.isAdminOrStaff(currentMembership)) {
			return true;
		}

		if (models.membership.isPlayer(currentMembership)) {
			if (
				models.membership.isPlayer(membership) || // Other players
				models.membership.isAdminOrStaff(membership) // Admin and staff
			) {
				return true;
			}
		}

		if (models.membership.isParent(currentMembership)) {
			if (
				models.membership.isAdminOrStaff(membership) || // Admin and staff
				models.membership.isParent(membership) || // Other parents
				currentMembership.targetUserId === membership.userId // Their own child
			) {
				return true;
			}
		}

		return false;
	};

	const updateUserRole = async (role: number) => {
		const updatedMembership = await actions.groups.deprecatedUpdateGroupMember(
			membership.groupId,
			membership.userId,
			{
				role,
			}
		)(store.dispatch, store.getState);

		if (updatedMembership) {
			replaceRecord(updatedMembership);
		}
	};

	const updateUserStatus = async (status: number) => {
		const updatedMembership = await actions.groups.deprecatedUpdateGroupMember(
			membership.groupId,
			membership.userId,
			{
				status,
			}
		)(store.dispatch, store.getState);

		if (updatedMembership) {
			removeRecord(updatedMembership.id);
		}
	};

	const handleSetAsUser = () => {
		if (confirm(t('Convert this user into a player?'))) {
			updateUserRole(1);
		}
	};

	const handleSetAsStaff = () => {
		if (models.membership.isStaff(membership) && !isSelf) {
			handleSetAsUser();
			return;
		}

		if (confirm(t('Convert this user into staff?'))) {
			updateUserRole(5);
		}
	};

	const handleSetAsAdmin = () => {
		if (models.membership.isAdmin(membership) && !isSelf) {
			handleSetAsUser();
			return;
		}

		if (confirm(t('Convert this user into an administrator?'))) {
			updateUserRole(2);
		}
	};

	const openSetTitleModal = () => setModal('title');
	const handleOpenOverdueModal = () => setModal('overdue');
	const closeModal = () => setModal('');

	const handleRemoveUser = async (
		removeUserRecursively: boolean,
		removeFromFutureEvents: boolean
	) => {
		const req = await actions.memberships.removeUserFromGroup(
			membership.groupId,
			membership.userId,
			{
				removeUserRecursively,
				removeFromFutureEvents,
			}
		);

		if (req) {
			removeRecord(membership.id);
		}
	};

	const removeUserDialog = useRemoveUserDialog({
		handleRemoveUsers: handleRemoveUser,
	});

	const handleBlockUser = () => {
		if (
			confirm(
				t(
					"Do you want to block this user? The user won't be able to rejoin your group until the block is lifted."
				)
			)
		) {
			updateUserStatus(2);
		}
	};

	const viewProfileItem = (
		<ContextMenu.LinkItem
			key="view-profile-item"
			href={routes.User.Profile.Show(
				org.id,
				membership.groupId,
				membership.userId,
				'overview'
			)}>
			<ContextMenu.ItemIcon name="person" />
			{t('View profile')}
		</ContextMenu.LinkItem>
	);

	const showEditProfileItem = (
		<ContextMenu.LinkItem
			key="show-edit-profile-item"
			href={routes.User.Settings(org.id, membership.userId)}>
			<ContextMenu.ItemIcon name="edit" />
			{t('Edit profile')}
		</ContextMenu.LinkItem>
	);

	const performanceReviewItem = (
		<ContextMenu.LinkItem
			key="performance-review-item"
			href={routes.User.Profile.PerformanceReviews(
				org.id,
				membership.groupId,
				membership.userId
			)}>
			<ContextMenu.ItemIcon name="forum" />
			{t('Performance review')}
		</ContextMenu.LinkItem>
	);

	const goalsItem = (
		<ContextMenu.LinkItem
			key="goals-item"
			href={routes.User.Profile.Goals(
				org.id,
				membership.groupId,
				membership.userId
			)}>
			<ContextMenu.ItemIcon name="flag" />
			{t('Goals')}
		</ContextMenu.LinkItem>
	);

	const notesItem = (
		<ContextMenu.LinkItem
			key="notes-item"
			href={routes.User.Profile.Show(
				org.id,
				membership.groupId,
				membership.userId,
				'player-logs'
			)}>
			<ContextMenu.ItemIcon name="sticky_note" />
			{t('Notes')}
		</ContextMenu.LinkItem>
	);

	const chatItem = (
		<ContextMenu.LinkItem
			key="chat-item"
			href={link(routes.Chat.New(org.id), newChatParams([membership.user]))}>
			<ContextMenu.ItemIcon name="add_comment" />
			{t('Message')}
		</ContextMenu.LinkItem>
	);

	const emailUserItem = (
		<ContextMenu.LinkItem
			key="email-user-item"
			href={`mailto:${membership.user.email}`}>
			<ContextMenu.ItemIcon name="mail" />
			{t('Send email')}
		</ContextMenu.LinkItem>
	);

	const changeRoleItems = (
		<Fragment key="change-role-items">
			<ContextMenu.Divider />

			<ContextMenu.Label>{t('Role')}</ContextMenu.Label>

			{!isSelf && (
				<ContextMenu.ControlItem
					onClick={handleSetAsUser}
					type="radio"
					icon="person_add"
					checked={models.membership.isPlayer(membership)}>
					{t('User')}
				</ContextMenu.ControlItem>
			)}

			<ContextMenu.ControlItem
				onClick={handleSetAsStaff}
				type="radio"
				icon="person_add"
				checked={models.membership.isStaff(membership)}>
				{t('Staff')}
			</ContextMenu.ControlItem>

			<ContextMenu.ControlItem
				onClick={handleSetAsAdmin}
				type="radio"
				icon="person_add"
				checked={models.membership.isAdmin(membership)}>
				{t('Coach')}
			</ContextMenu.ControlItem>

			{models.membership.isAdminOrStaff(currentMembership) && (
				<Fragment>
					<ContextMenu.Divider />

					<ContextMenu.Item onClick={openSetTitleModal}>
						<ContextMenu.ItemIcon name="arrow_forward" />
						{t('Set title')}
					</ContextMenu.Item>
				</Fragment>
			)}
		</Fragment>
	);

	const handleUserItems = (
		<Fragment key="handle-user-items">
			<ContextMenu.Divider />

			<RemoveUserItem dialog={removeUserDialog} />

			<ContextMenu.LinkItem caution onClick={handleBlockUser}>
				<ContextMenu.ItemIcon name="lock" />
				{t('Block')}
			</ContextMenu.LinkItem>
		</Fragment>
	);

	const overDueProductsItem = userHasOverduePayments ? (
		<ContextMenu.Item
			key="over-due-products-item"
			onClick={handleOpenOverdueModal}>
			<ContextMenu.ItemIcon name="sell" />
			{t('View overdue products')}
		</ContextMenu.Item>
	) : null;

	const actionMenuItems: JSX.Element[] = [];

	if (
		isSelf ||
		isParentForMembership ||
		models.membership.isAdminOrStaff(currentMembership)
	) {
		actionMenuItems.push(viewProfileItem);
	}

	if (isSelf || isParentForMembership) {
		actionMenuItems.push(showEditProfileItem);
	}

	if (models.membership.isAdmin(currentMembership)) {
		if (models.membership.isPlayer(membership)) {
			if (
				models.group.hasFeature(
					group,
					models.group.Features.IndividualDevelopment
				)
			) {
				actionMenuItems.push(performanceReviewItem);
				actionMenuItems.push(goalsItem);
			}
			actionMenuItems.push(notesItem);
			actionMenuItems.push(emailUserItem);
			actionMenuItems.push(overDueProductsItem);
			actionMenuItems.push(changeRoleItems);
			actionMenuItems.push(handleUserItems);
		} else if (models.membership.isAdminOrStaff(membership)) {
			actionMenuItems.push(emailUserItem);
			actionMenuItems.push(overDueProductsItem);
			actionMenuItems.push(changeRoleItems);
			actionMenuItems.push(handleUserItems);
		} else if (models.membership.isParent(membership)) {
			actionMenuItems.push(emailUserItem);
			actionMenuItems.push(overDueProductsItem);
		}
	}

	if (models.membership.isStaff(currentMembership)) {
		if (models.membership.isPlayer(membership)) {
			if (
				models.group.hasFeature(
					group,
					models.group.Features.IndividualDevelopment
				)
			) {
				actionMenuItems.push(performanceReviewItem);
				actionMenuItems.push(goalsItem);
			}
			actionMenuItems.push(emailUserItem);
			actionMenuItems.push(notesItem);
		}
	}
	if (actionMenuItems.length === 0) {
		if (showChatItem()) {
			return (
				<Button
					className={css.chatButton}
					icon="add_comment"
					iconSize={1.5}
					inline
					href={link(routes.Chat.New(org.id), newChatParams([membership.user]))}
				/>
			);
		} else {
			return null;
		}
	} else {
		if (showChatItem()) {
			actionMenuItems.splice(1, 0, chatItem);
		}
	}

	return (
		<Fragment>
			<ContextMenu.Menu
				toggleWith={
					<ContextMenu.ButtonTrigger>
						<Icon name="context-menu" />
					</ContextMenu.ButtonTrigger>
				}>
				{actionMenuItems}
			</ContextMenu.Menu>
			{modal === 'title' && (
				<TitleModal
					membership={membership}
					onClose={closeModal}
					replaceRecord={replaceRecord}
				/>
			)}
			{modal === 'overdue' && (
				<OverdueUserProductsModal
					membership={membership}
					onClose={closeModal}
				/>
			)}
		</Fragment>
	);
};
