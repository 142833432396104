// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lh7gjAgrMAneS3BWKCIg {
	padding: var(--spacing-5);
	display: grid;
	grid-column-gap: var(--spacing-4);
	-moz-column-gap: var(--spacing-4);
	     -webkit-column-gap: var(--spacing-4);
	     column-gap: var(--spacing-4);
	grid-template-columns: auto 1fr;
	grid-template-rows: repeat(2, 1fr);
	grid-template-areas:
		'avatar firstName'
		'avatar lastName';
}

@media (max-width: 768px) {

.lh7gjAgrMAneS3BWKCIg {
		color: var(--palette-white);
}
	}

.JUp1NegjepXOpEyrAXkj {
	--size: 60px;

	align-self: center;
	width: var(--size);
	height: var(--size);
	grid-area: avatar;
}

.Y752v_BtVxDzeVNRvgmv,
.L5_OLOTUN8HU3C0_gfZQ {
	font-size: var(--font-size-xl);
}

.Y752v_BtVxDzeVNRvgmv {
	align-self: end;
	grid-area: firstName;
}

.L5_OLOTUN8HU3C0_gfZQ {
	align-self: start;
	grid-area: lastName;
	font-weight: var(--font-weight-bold);
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/user-header/styles.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,aAAa;CACb,iCAA4B;CAA5B,iCAA4B;MAA5B,oCAA4B;MAA5B,4BAA4B;CAC5B,+BAA+B;CAC/B,kCAAkC;CAClC;;mBAEkB;AAKnB;;AAHC;;AAVD;EAWE,2BAA2B;AAE7B;CADC;;AAGD;CACC,YAAY;;CAEZ,kBAAkB;CAClB,kBAAkB;CAClB,mBAAmB;CACnB,iBAAiB;AAClB;;AAEA;;CAEC,8BAA8B;AAC/B;;AAEA;CACC,eAAe;CACf,oBAAoB;AACrB;;AAEA;CACC,iBAAiB;CACjB,mBAAmB;CACnB,oCAAoC;AACrC","sourcesContent":[".userHeader {\n\tpadding: var(--spacing-5);\n\tdisplay: grid;\n\tcolumn-gap: var(--spacing-4);\n\tgrid-template-columns: auto 1fr;\n\tgrid-template-rows: repeat(2, 1fr);\n\tgrid-template-areas:\n\t\t'avatar firstName'\n\t\t'avatar lastName';\n\n\t@media (--small-viewport) {\n\t\tcolor: var(--palette-white);\n\t}\n}\n\n.avatar {\n\t--size: 60px;\n\n\talign-self: center;\n\twidth: var(--size);\n\theight: var(--size);\n\tgrid-area: avatar;\n}\n\n.firstName,\n.lastName {\n\tfont-size: var(--font-size-xl);\n}\n\n.firstName {\n\talign-self: end;\n\tgrid-area: firstName;\n}\n\n.lastName {\n\talign-self: start;\n\tgrid-area: lastName;\n\tfont-weight: var(--font-weight-bold);\n}\n"],"sourceRoot":""}]);
// Exports
export var userHeader = `lh7gjAgrMAneS3BWKCIg`;
export var avatar = `JUp1NegjepXOpEyrAXkj`;
export var firstName = `Y752v_BtVxDzeVNRvgmv`;
export var lastName = `L5_OLOTUN8HU3C0_gfZQ`;
export default ___CSS_LOADER_EXPORT___;
