import { Fragment } from 'react';
import { T, useT } from '@transifex/react';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useCurrentOrganization } from 'pkg/identity';

import UserHeader from 'routes/organization/user-profile/user-header';
import {
	UserAction,
	UserActions,
} from 'routes/organization/user-profile/user-actions';

import MaterialSymbol from 'components/material-symbols';

import CollapsiblePanel from 'components/sidebar/collapsible-panel';
import Panel from 'components/sidebar/panel';
import { LayoutPortal } from 'components/layout/portal';
import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

export default function UserProfile(): JSX.Element {
	const t = useT();
	const { userId } = useCurrentRoute();
	const org = useCurrentOrganization();

	const { record: user, isLoading } = useEndpoint<models.user.User>(
		endpoints.Users.Show(userId)
	);

	const possibleActions: UserAction[] = [
		{ label: t('Edit'), symbol: 'edit', condition: models.Action.UserUpdate },
		{
			label: t('Invoice'),
			symbol: 'request_quote',
			condition: models.Action.UserListInvoices,
		},
		{
			label: t('Group'),
			symbol: 'group_add',
			condition: models.Action.UserListGroups,
		},
		{
			label: t('Chat'),
			symbol: 'chat_bubble',
			condition: models.Action.UserUpdate,
		},
		{
			label: t('Add note'),
			symbol: 'note_stack_add',
			condition: models.Action.UserUpdate,
		},
		{
			label: t('Send email'),
			symbol: 'outgoing_mail',
			condition: models.Action.UserUpdate,
		},
		{
			divider: true,
			label: t('Assign product'),
			symbol: 'sell',
			condition: models.Action.UserUpdate,
		},
		{
			label: t('Select billing contact'),
			symbol: 'person_add',
			condition: models.Action.UserUpdate,
		},
		{
			divider: true,
			label: t('Archive contact'),
			symbol: 'delete',
			condition: models.Action.UserUpdate,
			dialog: {
				destructive: true,
				symbol: 'person_off',
				symbolScale: 1.5,
				title: t('Archive {user}?', { user: user.firstName }),
				message: (
					<T
						_str="Archiving {user} will make them lose their access to {org}."
						user={<strong>{models.user.fullName(user)}</strong>}
						org={<strong>{org.name}</strong>}
					/>
				),
				confirmLabel: t('Archive'),
				onConfirm: () => true,
			},
		},
	];

	return (
		<Fragment>
			<LayoutPortal portalId="user-profile-header">
				<UserHeader user={user} />
			</LayoutPortal>

			<LayoutPortal portalId="user-profile-sidebar">
				<UserActions user={user} actions={possibleActions} />

				<CollapsiblePanel heading={t('Summary')}>
					<p>
						Powder pudding cheesecake cookie fruitcake wafer cookie tart dragée.
						Powder topping lemon drops topping donut powder pastry. Chocolate
						bar liquorice cupcake cupcake gummi bears powder gummi bears danish
						ice cream. Pie chocolate chocolate bar macaroon brownie topping
						cotton candy.
					</p>
				</CollapsiblePanel>

				<CollapsiblePanel
					defaultCollapsed
					heading={t('Details')}
					additionalAction={
						<Button small secondary>
							Action
						</Button>
					}>
					<p>Hello World!</p>
				</CollapsiblePanel>

				<CollapsiblePanel
					heading={t(
						'Panel with a really long title that will overflow its container'
					)}
					additionalAction={
						<MaterialSymbol variant="bookmark_add" scale={1.25} />
					}>
					<p>Hello World!</p>
				</CollapsiblePanel>

				<Panel footer>Hi, I'm a sidebar footer panel.</Panel>
			</LayoutPortal>

			{isLoading && <Spinner />}

			<p>
				Hello There, this is the main content of this layout. The LayoutPortals
				will be rendered elsewhere.
			</p>
		</Fragment>
	);
}
