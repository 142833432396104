import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as models from 'pkg/api/models';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';

import * as Context from 'design/context_menu';
import Button from 'design/button';
import { DialogOptions, useDialog } from 'design/dialog';

import * as css from './styles.css';

export interface UserAction {
	label: string;
	symbol: MaterialSymbolVariant;
	condition: models.Action;

	caution?: boolean;
	divider?: boolean;

	href?: string;
	onClick?: () => void;

	dialog?: DialogOptions;
}

interface UserActionProps {
	user: models.user.User;
	actions: UserAction[];
}

export function UserActions({ user, actions }: UserActionProps): JSX.Element {
	const numActions: number = actions.length;

	// Filter out allowed actions
	actions = actions.filter(({ condition }) =>
		models.hasAllowedAction(user, condition)
	);

	let visibleActions: UserAction[] = [];
	let contextActions: UserAction[] = [];

	if (numActions > 4) {
		visibleActions = actions.slice(0, 3);
		contextActions = actions.slice(3);
	} else {
		visibleActions = actions;
	}

	return (
		<div className={css.wrapper}>
			{visibleActions.map(({ label, symbol, href, onClick }) => (
				<Button
					iconPosition="top"
					icon={symbol}
					href={href}
					onClick={onClick}
					secondary
					block>
					{label}
				</Button>
			))}
			{contextActions.length > 0 && (
				<Context.Menu
					toggleWith={
						<Button secondary block iconPosition="top" icon="more_horiz">
							{t('More')}
						</Button>
					}>
					{contextActions.map((itemProperties, index) => (
						<Fragment>
							{itemProperties?.divider && index !== 0 && <Context.Divider />}
							<UserContextMenuItem {...itemProperties} />
						</Fragment>
					))}
				</Context.Menu>
			)}
		</div>
	);
}

function UserContextMenuItem({
	label,
	symbol,
	caution,
	href,
	onClick,
	dialog,
}: UserAction): JSX.Element {
	const ctx = Context.useContextMenu();

	const dialogAction = useDialog({
		...dialog,
		// Intercept and handle confirm action
		onConfirm: async () => {
			const didConfirm = await dialog.onConfirm();

			if (didConfirm) {
				ctx.close();
			}

			return didConfirm;
		},
	});

	const item = (
		<Fragment>
			<Context.ItemIcon name={symbol} />
			{label}
		</Fragment>
	);

	if (dialog) {
		return (
			<Context.Item
				caution={caution}
				closeOnClick={false}
				onClick={dialogAction}>
				{item}
			</Context.Item>
		);
	}

	if (href) {
		return <Context.LinkItem href={href}>{item}</Context.LinkItem>;
	}

	return <Context.Item onClick={onClick}>{item}</Context.Item>;
}
