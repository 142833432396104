import React, { useEffect } from 'react';
import { t } from '@transifex/native';
import { useFormContext, useWatch } from 'react-hook-form';

import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import * as Inputs from 'components/form/inputs';
import Section from 'components/form/Section';
import InfoBox from 'components/form/info-box';

import Button from 'design/button';

import * as styles from './styles.css';

interface Props {
	discount: models.discount.Discount;
	onApply: (discount: string) => void;
	onRemove: () => void;
	incorrectCode: boolean;
	wiggleTrigger: number;
}

const Discount = ({
	discount,
	onApply,
	onRemove,
	incorrectCode,
	wiggleTrigger,
}: Props) => {
	const { getValues, control, setValue } = useFormContext();
	const discountCode = useWatch({ control, name: 'discount' });
	const [shouldWiggle, setShouldWiggle] = React.useState(false);

	const handleApply = () => {
		const discountCode = getValues(['discount'])[0];
		onApply(discountCode);
	};

	const handleRemove = () => {
		onRemove();
		setValue('discount', '');
	};

	useEffect(() => {
		if (incorrectCode) {
			setShouldWiggle(true);
		}
	}, [wiggleTrigger, incorrectCode]);

	const handleAnimationEnd = () => {
		setShouldWiggle(false);
	};

	return (
		<Section title={t('Discount code')}>
			{discount && (
				<div className={styles.discount_title_wrapper}>
					<div className={styles.discount_title}>
						{t('Applied discount: ')}
						{discount.title}
					</div>
					<Button icon="cancel" inline noPadding onClick={handleRemove}>
						{t('Remove')}
					</Button>
				</div>
			)}
			{incorrectCode && (
				<div className={styles.incorrectInfoBox}>
					<InfoBox color="red" text={t('Invalid discount code')} />
				</div>
			)}
			<div
				className={cssClasses(
					`${incorrectCode ? styles.incorrectCode : styles.field_wrapper} ${
						shouldWiggle ? styles.wiggle : ''
					}`
				)}
				onAnimationEnd={handleAnimationEnd}>
				<Inputs.Field name="discount" />
				<Button primary onClick={handleApply} disabled={!discountCode}>
					{t('Use code')}
				</Button>
			</div>
		</Section>
	);
};

export default Discount;
