import styled from 'styled-components';
import { t } from '@transifex/native';
import { Fragment } from 'react';

import * as styles from 'pkg/config/styles';

import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import DateTime from 'pkg/datetime';
import Link from 'pkg/router/Link';
import * as routes from 'pkg/router/routes';
import { formatToCurrency } from 'pkg/i18n/formatters';
import { pushState } from 'pkg/router/state';
import { Statuses } from 'pkg/api/models/order';
import { toFilterQueryUrl } from 'pkg/filters/use_filters';
import { FilterOperator, createQueryFilter } from 'pkg/filters';
import { useCurrentOrganization } from 'pkg/identity';

import { SingleFormTabViews } from 'routes/forms/single';

import MissingEntities from 'components/missing-entities';
import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import Avatar from 'components/avatar';

import { Inner } from 'components/layout/LargeScreenContent';
import { Spinner } from 'components/loaders/spinner';
import Column from 'components/layout/column';
import Row from 'components/layout/row';
import StatusLabel from 'components/payment_platform/status/label';
import InfoBox from 'components/form/info-box';
import { usePageActions } from 'components/navigation/header/small_screen/page_actions/Context';

import * as DataCard from 'design/data_card';
import * as Card from 'design/card';

import * as css from './styles.css';

interface FormsDashboardProps {
	formId: number;
	groupId: number;
}

interface FormSummary {
	Settings: {
		TransactionSummary: boolean;
		SubmissionSummary: boolean;
		SubscriptionSummary: boolean;
		OverduePayments: boolean;
		LatestSubmissions: boolean;
	};
	TransactionSummary: {
		Total: number;
		Statuses: {
			Paid: number;
			Open: number;
			PastDue: number;
			Void: number;
			Uncollectible: number;
		};
	};
	SubmissionSummary: {
		Total: number;
		Today: number;
		SevenDays: number;
		ThirtyDays: number;
	};
	SubscriptionSummary: {
		Total: number;
		Statuses: {
			Paid: number;
			Uncollectible: number;
			PastDue: number;
			Canceled: number;
		};
	};
	OverduePayments: models.order.Order[];

	LatestSubmissions: models.formSubmission.FormSubmission[];

	Form: models.form.Form;
}

const Grayed = styled.div`
	color: var(--palette-gray-500);
	flex: 1;
	font-size: var(--font-size-sm);
`;
const EmailStyle = styled.div`
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);
`;
const BoldName = styled.div`
	font-weight: var(--font-weight-semibold);
`;

interface DashboardComponentProps extends FormsDashboardProps {
	summary: FormSummary;
}
const LatestSubmissions = ({
	summary,
	formId,
	groupId,
}: DashboardComponentProps) => {
	const org = useCurrentOrganization();
	if (!summary.Settings.LatestSubmissions) {
		return (
			<Card.Base $noBorder>
				<Card.Body>
					<MissingEntities centered>
						<Icon name="list" />
						<p>{t(`No submissions yet`)}</p>
					</MissingEntities>
				</Card.Body>
			</Card.Base>
		);
	}
	return (
		<Fragment>
			{summary.Settings.LatestSubmissions && (
				<Card.Base $noBorder>
					<Card.Header $slim>
						<Card.Heading>{t(`Latest submissions`)}</Card.Heading>
						<Link
							href={routes.Registrations.Single(
								org.id,
								groupId,
								formId,
								'submissions'
							)}>
							{t(`View`)} <Icon name="chevron" size={1.4} />
						</Link>
					</Card.Header>
					<Card.Body>
						<div>
							<Column>
								{summary.LatestSubmissions.map(
									(formSubmission, i, allSubmissions) => {
										const createdDate = DateTime.fromTimestamp(
											formSubmission.updatedAt
										);

										return (
											<Fragment key={formSubmission.id}>
												{formSubmission.submittedForUser ? (
													<Link
														href={routes.Form.Submissions.Single(
															org.id,
															groupId,
															formId,
															formSubmission.id
														)}>
														<Row columns="30px 1fr auto auto" align="center">
															<Avatar user={formSubmission.submittedForUser} />
															<Column spacing={styles.spacing._1}>
																<BoldName>
																	{models.user.fullName(
																		formSubmission.submittedForUser
																	)}
																</BoldName>
																<EmailStyle>
																	{formSubmission.submittedForUser.email}
																</EmailStyle>
																<SmallScreen>
																	<Grayed>
																		{createdDate.toLocaleDateString({
																			year: 'numeric',
																			month: 'numeric',
																			day: 'numeric',
																		})}
																	</Grayed>
																</SmallScreen>
															</Column>
															{summary.Settings.TransactionSummary &&
																formSubmission.order && (
																	<StatusLabel
																		status={models.order.getStatus(
																			formSubmission.order
																		)}
																		jobStatus={formSubmission.order.jobStatus}
																	/>
																)}
															<LargeScreen>
																<Grayed>
																	{createdDate.toLocaleDateString({
																		year: 'numeric',
																		month: 'numeric',
																		day: 'numeric',
																	})}
																</Grayed>
															</LargeScreen>
														</Row>
													</Link>
												) : (
													<div>{t('New submission')}</div>
												)}
												{i + 1 !== allSubmissions.length && <Card.Divider />}
											</Fragment>
										);
									}
								)}
							</Column>
						</div>
					</Card.Body>
				</Card.Base>
			)}
		</Fragment>
	);
};
const SubmissionSummary = ({ summary }: DashboardComponentProps) => {
	return (
		<Fragment>
			{summary.Settings.SubmissionSummary && (
				<DataCard.WithLargeIcon
					icon="registration"
					title={t(`Submissions`)}
					heading={summary.SubmissionSummary.Total.toString()}
					headingSuffix={t(`Total amount`)}>
					<DataCard.Breakdown
						items={[
							{
								title: t('Today'),
								value: summary.SubmissionSummary.Today.toString(),
							},
							{
								title: t('Week'),
								value: summary.SubmissionSummary.SevenDays.toString(),
							},
							{
								title: t('30 days'),
								value: summary.SubmissionSummary.ThirtyDays.toString(),
							},
						]}
					/>
				</DataCard.WithLargeIcon>
			)}
		</Fragment>
	);
};
const FormsDashboard = ({ groupId, formId }: FormsDashboardProps) => {
	const org = useCurrentOrganization();
	usePageActions(
		[
			{
				label: t('Edit'),
				href: routes.Registrations.Edit(org.id, groupId, formId),
			},
		],
		'edit'
	);

	const { record: summary, isLoading } = useEndpoint<FormSummary>(
		endpoints.Forms.Summary(formId)
	);
	const organization = useCurrentOrganization();
	if (isLoading) {
		return <Spinner />;
	}
	const paidPercent =
		(summary.TransactionSummary.Statuses.Paid /
			summary.TransactionSummary.Total) *
		100;
	const openPercent =
		(summary.TransactionSummary.Statuses.Open /
			summary.TransactionSummary.Total) *
		100;
	const pastDuePercent =
		(summary.TransactionSummary.Statuses.PastDue /
			summary.TransactionSummary.Total) *
		100;
	const uncollectiblePercent =
		(summary.TransactionSummary.Statuses.Uncollectible /
			summary.TransactionSummary.Total) *
		100;

	const makeOrderStatusFilterUrl = (status: Statuses): string => {
		return toFilterQueryUrl(
			routes.Registrations.Single(
				org.id,
				groupId,
				formId,
				SingleFormTabViews.Invoices
			),
			{
				status: createQueryFilter({
					operator: FilterOperator.Includes,
					values: [status],
				}),
			}
		);
	};

	if (
		!summary.Settings.OverduePayments &&
		!summary.Settings.SubscriptionSummary &&
		!summary.Settings.TransactionSummary
	) {
		return (
			<Inner>
				<Column spacing={styles.spacing._7}>
					{summary.Form.publicUrl && (
						<InfoBox>
							<div className={css.linkStyle}>
								{t('View published form')}:{' '}
								<Link href={summary.Form.publicUrl}>
									{summary.Form.publicUrl}
								</Link>
							</div>
						</InfoBox>
					)}
					<SubmissionSummary
						summary={summary}
						groupId={groupId}
						formId={formId}
					/>
					<LatestSubmissions
						summary={summary}
						groupId={groupId}
						formId={formId}
					/>
				</Column>
			</Inner>
		);
	}

	return (
		<Inner>
			<Column spacing={styles.spacing._7}>
				{summary.Form.publicUrl && (
					<InfoBox>
						<div className={css.linkStyle}>
							{t('View published form')}:
							<Link href={summary.Form.publicUrl}>
								{summary.Form.publicUrl}
							</Link>
						</div>
					</InfoBox>
				)}
				<Row
					align="stretch"
					columns="1fr 1fr"
					spacing={styles.spacing._7}
					collapseOnSmallScreens>
					{summary.Settings.TransactionSummary && (
						<DataCard.WithLargeIcon
							icon="nav-payments"
							title={t(`Transaction Volume`)}
							heading={formatToCurrency(
								summary.TransactionSummary.Total / 100,
								organization.currency
							)}
							headingSuffix={t(`Total amount`)}>
							<DataCard.Breakdown
								items={[
									{
										variant: 'label',
										color: 'green',
										title: t('Paid'),
										value: formatToCurrency(
											summary.TransactionSummary.Statuses.Paid / 100,
											organization.currency
										),
										diff: Number.isFinite(paidPercent)
											? `${Math.round(paidPercent)}%`
											: '0%',
										onClick: () =>
											pushState(makeOrderStatusFilterUrl(Statuses.Paid)),
									},
									{
										variant: 'label',
										color: 'blue',
										title: t('Open'),
										value: formatToCurrency(
											summary.TransactionSummary.Statuses.Open / 100,
											organization.currency
										),
										diff: Number.isFinite(openPercent)
											? `${Math.round(openPercent)}%`
											: '0%',
										onClick: () =>
											pushState(makeOrderStatusFilterUrl(Statuses.Open)),
									},
									{
										variant: 'label',
										color: 'red',
										title: t('Past due'),
										value: formatToCurrency(
											summary.TransactionSummary.Statuses.PastDue / 100,
											organization.currency
										),
										diff: Number.isFinite(pastDuePercent)
											? `${Math.round(pastDuePercent)}%`
											: '0%',
										onClick: () =>
											pushState(makeOrderStatusFilterUrl(Statuses.PastDue)),
									},
									{
										variant: 'label',
										color: 'orange',
										title: t('Uncollectible'),
										value: formatToCurrency(
											summary.TransactionSummary.Statuses.Uncollectible / 100,
											organization.currency
										),
										diff: Number.isFinite(uncollectiblePercent)
											? `${Math.round(uncollectiblePercent)}%`
											: '0%',
										onClick: () =>
											pushState(
												makeOrderStatusFilterUrl(Statuses.Uncollectible)
											),
									},
								]}
							/>
						</DataCard.WithLargeIcon>
					)}
					<Row
						align="stretch"
						spacing={styles.spacing._7}
						collapseOnSmallScreens>
						{summary.Settings.SubscriptionSummary && (
							<DataCard.WithLargeIcon
								icon="reload"
								title={t(`Subscriptions`)}
								heading={summary.SubscriptionSummary.Total.toString()}
								headingSuffix={t(`Total active`)}>
								<DataCard.Breakdown
									items={[
										{
											variant: 'label',
											color: 'green',
											title: t('Paid'),
											value:
												summary.SubscriptionSummary.Statuses.Paid.toString(),
										},
										{
											variant: 'label',
											color: 'red',
											title: t('Past due'),
											value:
												summary.SubscriptionSummary.Statuses.PastDue.toString(),
										},
										{
											variant: 'label',
											color: 'orange',
											title: t('Uncollectible'),
											value:
												summary.SubscriptionSummary.Statuses.Uncollectible.toString(),
										},
										{
											variant: 'label',
											color: 'gray',
											title: t('Canceled'),
											value:
												summary.SubscriptionSummary.Statuses.Canceled.toString(),
										},
									]}
								/>
							</DataCard.WithLargeIcon>
						)}
						<SubmissionSummary
							summary={summary}
							groupId={groupId}
							formId={formId}
						/>
					</Row>
				</Row>
				<Row
					spacing={styles.spacing._7}
					columns="1fr 1fr"
					collapseOnSmallScreens>
					{!summary.Settings.OverduePayments &&
						summary.Settings.TransactionSummary && (
							<Card.Base $noBorder>
								<Card.Body>
									<MissingEntities centered>
										<Icon name="nav-payments" />
										<p>{t(`No overdue payments here!`)}</p>
									</MissingEntities>
								</Card.Body>
							</Card.Base>
						)}
					{summary.Settings.OverduePayments && (
						<Card.Base>
							<Card.Header $slim>
								<Card.Heading>{t(`Overdue Payments`)}</Card.Heading>
							</Card.Header>
							<Card.Body>
								<div>
									<Column>
										{summary.OverduePayments?.map((order, i, allOrders) => {
											const createdDate = new DateTime(
												new Date(order.updatedAt * 1000)
											);

											return (
												<Fragment key={order.id}>
													<Link href={routes.Order.Show(org.id, order.id)}>
														<Row columns="30px 1fr auto auto" align="center">
															<Avatar user={order.customerUser} />
															<Column spacing={styles.spacing._1}>
																<BoldName>
																	{order.customerUser.firstName}{' '}
																	{order.customerUser.lastName}
																</BoldName>
																<EmailStyle>
																	{order.customerUser.email}
																</EmailStyle>
																<SmallScreen>
																	<Grayed>
																		{createdDate.toLocaleDateString({
																			year: 'numeric',
																			month: 'numeric',
																			day: 'numeric',
																		})}
																	</Grayed>
																</SmallScreen>
															</Column>
															<StatusLabel
																status={models.order.getStatus(order)}
																jobStatus={order.jobStatus}
															/>
															<LargeScreen>
																<Grayed>
																	{createdDate.toLocaleDateString({
																		year: 'numeric',
																		month: 'numeric',
																		day: 'numeric',
																	})}
																</Grayed>
															</LargeScreen>
														</Row>
													</Link>
													{i + 1 !== allOrders.length && <Card.Divider />}
												</Fragment>
											);
										})}
									</Column>
								</div>
							</Card.Body>
						</Card.Base>
					)}

					<LatestSubmissions
						summary={summary}
						groupId={groupId}
						formId={formId}
					/>
				</Row>
			</Column>
		</Inner>
	);
};

export default FormsDashboard;
