import { Fragment, useMemo } from 'react';
import { t } from '@transifex/native';

import { Visibilities, Statuses } from 'pkg/models/form';

import * as flashActions from 'pkg/actions/flashes';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import DateTime from 'pkg/datetime';
import * as actions from 'pkg/actions';
import { useCurrentAccount, useCurrentOrganization } from 'pkg/identity';
import { formCategoryLinkFlag, inAppRegistrationsEnabled } from 'pkg/flags';
import { getTranslatedStatus } from 'pkg/api/models/form';
import copyString from 'pkg/copyString';

import Icon from 'components/icon';
import Label from 'components/label';

import * as Table from 'design/table';
import * as ContextMenu from 'design/context_menu';

interface ActiveFormRowProps {
	groupId: number;
	form: models.form.Form;
	replace: (newRecord: models.form.Form) => void;
	remove: (id: number) => void;
}

const ActiveFormRow: React.FC<React.PropsWithChildren<ActiveFormRowProps>> = ({
	groupId,
	form,
	replace,
	remove,
}) => {
	const org = useCurrentOrganization();
	const account = useCurrentAccount();

	const createdAt = useMemo(() => {
		return new DateTime(new Date(form.createdAt * 1000));
	}, [form.createdAt]);

	const handleOpenForm = async () => {
		const response = await models.update(form, {
			status: Statuses.Open,
		});
		replace(response[1] as models.form.Form);
	};

	const handleCloseForm = async () => {
		const response = await models.update(form, {
			status: Statuses.Closed,
		});
		replace(response[1] as models.form.Form);
	};

	const handleDraftForm = async () => {
		const response = await models.update(form, {
			status: Statuses.Draft,
		});
		replace(response[1] as models.form.Form);
	};

	const handlePublicForm = async () => {
		const response = await models.update(form, {
			visibility: Visibilities.Public,
		});
		replace(response[1] as models.form.Form);
	};

	const handleOrganizationForm = async () => {
		const response = await models.update(form, {
			visibility: Visibilities.Organization,
		});
		replace(response[1] as models.form.Form);
	};

	const handleUnlistedForm = async () => {
		const response = await models.update(form, {
			visibility: Visibilities.Unlisted,
		});
		replace(response[1] as models.form.Form);
	};

	const handleArchiveForm = async () => {
		await models.destroy(form);
		remove(form.id);
	};

	const handleCopyUrl = async () => {
		await navigator.clipboard.writeText(form.publicUrl);
		flashActions.show({ title: t('URL copied') });
	};

	const handleCopyCategoryUrl = async () => {
		copyString(
			`${document.location.origin}${routes.Registrations.Category(org.id, form.formCategoryId)}`
		);
		flashActions.show({ title: t('URL copied') });
	};

	const handleCopyExternalCategoryUrl = async () => {
		copyString(form.publicCategoryUrl);
		flashActions.show({ title: t('URL copied') });
	};

	const handleViewForm = async () => {
		const token = await actions.auth.createOneTimeLoginToken(account.id);
		if (token !== '') {
			window.open(form.publicUrl + '?_token=' + token);
		}
	};

	const canOpenForm =
		form.maxSubmissions === 0 || form.submissionCount < form.maxSubmissions;

	const contextMenu = (
		<Table.ActionCell>
			<ContextMenu.Menu
				toggleWith={
					<ContextMenu.TableTrigger>
						<Icon name="context-menu" />
					</ContextMenu.TableTrigger>
				}>
				{form.submissionCount > 0 && (
					<ContextMenu.LinkItem
						icon="list_alt"
						href={routes.Registrations.Single(
							org.id,
							groupId,
							form.id,
							'dashboard'
						)}>
						{t('View submissions')}
					</ContextMenu.LinkItem>
				)}
				{inAppRegistrationsEnabled() && (
					<ContextMenu.LinkItem
						icon="content_copy"
						href={routes.Registration.Preview(form.guid)}>
						{t('View registration')}
					</ContextMenu.LinkItem>
				)}
				<ContextMenu.Item icon="open_in_browser" onClick={handleViewForm}>
					{t('View external registration')}
				</ContextMenu.Item>
				<ContextMenu.LinkItem
					icon="edit"
					href={routes.Registrations.Edit(org.id, groupId, form.id)}>
					{t('Edit')}
				</ContextMenu.LinkItem>
				<ContextMenu.LinkItem
					icon="content_copy"
					href={routes.Registrations.Duplicate(org.id, groupId, form.id)}>
					{t('Duplicate form')}
				</ContextMenu.LinkItem>
				<ContextMenu.Divider />
				<ContextMenu.Label>{t('Share')}</ContextMenu.Label>
				<ContextMenu.Item
					icon="link"
					onClick={handleCopyUrl}
					closeOnClick={false}>
					{t('Copy form URL')}
				</ContextMenu.Item>
				{form.formCategoryId && formCategoryLinkFlag() && (
					<Fragment>
						<ContextMenu.Item
							icon="link"
							onClick={handleCopyCategoryUrl}
							closeOnClick={false}>
							{t('Copy category URL')}
						</ContextMenu.Item>
						<ContextMenu.Item
							icon="link"
							onClick={handleCopyExternalCategoryUrl}
							closeOnClick={false}>
							{t('Copy external category URL')}
						</ContextMenu.Item>
					</Fragment>
				)}
				<ContextMenu.Divider />
				<ContextMenu.Label>{t('Set status')}</ContextMenu.Label>

				<ContextMenu.ControlItem
					onClick={canOpenForm && handleOpenForm}
					type="radio"
					checked={form.status === Statuses.Open}
					disabled={!canOpenForm}>
					{t('Open')}
				</ContextMenu.ControlItem>

				<ContextMenu.ControlItem
					onClick={handleCloseForm}
					closeOnClick={false}
					type="radio"
					checked={form.status === Statuses.Closed}>
					{t('Closed')}
				</ContextMenu.ControlItem>

				<ContextMenu.ControlItem
					onClick={handleDraftForm}
					closeOnClick={false}
					type="radio"
					checked={form.status === Statuses.Draft}>
					{t('Draft')}
				</ContextMenu.ControlItem>
				<ContextMenu.Divider />
				<ContextMenu.Label>{t('Set visibility')}</ContextMenu.Label>
				<ContextMenu.ControlItem
					onClick={handlePublicForm}
					type="radio"
					checked={form.visibility === Visibilities.Public}>
					{t('Public')}
				</ContextMenu.ControlItem>

				<ContextMenu.ControlItem
					onClick={handleOrganizationForm}
					type="radio"
					checked={form.visibility === Visibilities.Organization}>
					{t('Club lobby')}
				</ContextMenu.ControlItem>

				<ContextMenu.ControlItem
					onClick={handleUnlistedForm}
					closeOnClick={false}
					type="radio"
					checked={form.visibility === Visibilities.Unlisted}>
					{t('Unlisted')}
				</ContextMenu.ControlItem>

				<ContextMenu.Divider />
				<ContextMenu.ConfirmItem
					icon="archive"
					onConfirm={handleArchiveForm}
					confirmLabel={t('Archive')}
					caution
					message={t('Are you sure you want to archive the form?')}>
					{t('Archive')}
				</ContextMenu.ConfirmItem>
			</ContextMenu.Menu>
		</Table.ActionCell>
	);

	const getStatusColor = () => {
		if (form.status === models.form.Statuses.Draft) {
			return 'gray';
		}

		if (form.status === models.form.Statuses.Closed) {
			return 'red';
		}

		return 'green';
	};

	const visibilityLabels = {
		public: t('Public'),
		organization: t('Club lobby'),
		unlisted: t('Unlisted'),
	};

	return (
		<Table.Row>
			<Table.LinkCell
				href={routes.Registrations.Single(
					org.id,
					groupId,
					form.id,
					'dashboard'
				)}>
				{form.title}
			</Table.LinkCell>
			<Table.LinkCell
				href={routes.Registrations.Single(
					org.id,
					groupId,
					form.id,
					'dashboard'
				)}>
				{form.formCategory ? form.formCategory.title : t(`Uncategorized`)}
			</Table.LinkCell>
			<Table.LinkCell
				href={routes.Registrations.Single(
					org.id,
					groupId,
					form.id,
					'dashboard'
				)}>
				{visibilityLabels[form.visibility]}
			</Table.LinkCell>
			<Table.LinkCell
				href={routes.Registrations.Single(
					org.id,
					groupId,
					form.id,
					'dashboard'
				)}>
				<Label color={getStatusColor()}>
					{getTranslatedStatus(form.status)}
				</Label>
			</Table.LinkCell>
			<Table.LinkCell
				href={routes.Registrations.Single(
					org.id,
					groupId,
					form.id,
					'dashboard'
				)}>
				{form.submissionCount}
				{`${form.maxSubmissions ? '/' + form.maxSubmissions : ''}`}
			</Table.LinkCell>
			<Table.LinkCell>{createdAt.toLocaleDateString()}</Table.LinkCell>
			{contextMenu}
		</Table.Row>
	);
};

export default ActiveFormRow;
