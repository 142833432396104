export enum Action {
	UserView = 'user_view',
	UserCreate = 'user_create',
	UserUpdate = 'user_update',
	UserListGroups = 'user_list_groups',
	UserListAssessments = 'user_list_assessments',
	UserListEvents = 'user_list_events',
	UserListGoals = 'user_list_goals',
	UserListInvoices = 'user_list_invoices',
	UserListLegalGuardians = 'user_list_legal_guardians',
	UserListNotes = 'user_list_notes',
	UserListPerformanceReview = 'user_list_performance_review',
	UserListProducts = 'user_list_products',
	UserListStats = 'user_list_stats',
	UserListSubmissions = 'user_list_submissions',
	UserListSubscriptions = 'user_list_subscriptions',
	UserListVideos = 'user_list_videos',
	UserListWards = 'user_list_wards',

	GroupView = 'group_view',
	GroupRemoveUser = 'group_remove_user',
	GroupBanUser = 'group_ban_user',

	OrderView = 'order_view',
	OrderMarkAsPaid = 'order_mark_as_paid',

	ExerciseDuplicate = 'exercise_duplicate',
	ExerciseView = 'exercise_view',

	AttachmentDuplicate = 'attachment_duplicate',

	OrganizationView = 'organization_view',
	OrganizationUpdateLOKSettings = 'organization_update_lok_settings',

	UserFieldCategoryIndex = 'user_field_category_index',
	UserFieldCategoryCreate = 'user_field_category_create',
	UserFieldCategoryUpdate = 'user_field_category_update',
	UserFieldCategoryRemove = 'user_field_category_remove',

	OrganizationLinkCreate = 'organization_link_create',
	OrganizationLinkDelete = 'organization_link_delete',

	TrainingCollectionView = 'training_collection_view',
	TrainingCollectionShare = 'training_collection_share',
	TrainingCollectionRemove = 'training_collection_remove',
	TrainingCollectionUpdate = 'training_collection_update',
	TrainingCollectionContribute = 'training_collection_contribute',

	IdentityProviderCreate = 'identity_provider_create',
	IdentityProviderDuplicate = 'identity_provider_duplicate',
}

export interface AllowedActionable {
	allowedActions?: Action[];
}
