// AUTO-GENERATED FILE. DO NOT EDIT MANUALLY.

export const Home = (): string => `/`;

export const Join = (groupCode: string): string => `/join/${groupCode}`;

export const Claim = (inviteKey: string): string => `/claim/${inviteKey}`;

export const Support = (): string => `/support`;

export const Notifications = (): string => `/notifications`;

export const Invite = {
	Show: (inviteId: number): string => `/invite/${inviteId}`,
};

export const Debug = {
	KitchenSink: (): string => `/debug/kitchen-sink`,
};

export const Group = {
	Change: (): string => `/group-change`,
	Formations: {
		New: (organizationId: number): string =>
			`/organization/${organizationId}/group/formations/new`,
		Show: (organizationId: number, formationId: number): string =>
			`/organization/${organizationId}/group/formations/${formationId}`,
	},
	Post: {
		Show: (organizationId: number, id: number): string =>
			`/organization/${organizationId}/group/post/${id}`,
		ListCopies: (organizationId: number, id: number): string =>
			`/organization/${organizationId}/group/post/${id}/copies`,
		Index: (
			organizationId: number,
			groupId: number,
			currentTab: string
		): string =>
			`/organization/${organizationId}/communication/${groupId}/posts/${currentTab}`,
	},
	Show: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/group/${groupId}`,
	Wall: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/group/${groupId}/wall`,
};

export const Account = {
	Settings: {
		Show: (currentTab: string): string => `/account/settings/${currentTab}`,
	},
};

export const UserBilling = {
	Home: (): string => `/account/billing`,
	Invoice: (orderId: number): string => `/account/billing/invoice/${orderId}`,
};

export const Organization = {
	Home: (organizationId: number): string => `/organization/${organizationId}`,
	User: {
		Profile: (organizationId: number, userId: number): string =>
			`/organization/${organizationId}/user/${userId}`,
	},
	SubGroup: {
		Settings: (organizationId: number, groupId: number): string =>
			`/organization/${organizationId}/group/${groupId}/subgroup-settings`,
	},
	Settings: {
		Sso: (organizationId: number): string =>
			`/organization/${organizationId}/settings/sso`,
	},
	Development: {
		Assessments: (organizationId: number, groupId: number): string =>
			`/organization/${organizationId}/development/${groupId}/assessments`,
		PerformanceReviews: (organizationId: number, groupId: number): string =>
			`/organization/${organizationId}/development/${groupId}/performance-reviews`,
	},
	Events: (organizationId: number, eventId: number): string =>
		`/organization/${organizationId}/events/${eventId}`,
	Videos: {
		Edit: (organizationId: number, videoId: number): string =>
			`/organization/${organizationId}/videos/${videoId}/edit`,
	},
};

export const Profile = {
	Show: (organizationId: number, userId: number): string =>
		`/organization/${organizationId}/profile/${userId}`,
};

export const Payments = {
	Overview: (organizationId: number): string =>
		`/organization/${organizationId}/payments/overview`,
	Orders: (organizationId: number): string =>
		`/organization/${organizationId}/payments/orders`,
	Subscriptions: (organizationId: number): string =>
		`/organization/${organizationId}/payments/subscriptions`,
	Payouts: (organizationId: number): string =>
		`/organization/${organizationId}/payments/payouts`,
};

export const Payout = {
	Index: (organizationId: number, id: number): string =>
		`/organization/${organizationId}/payments/payouts/${id}`,
};

export const Taxes = {
	Index: (organizationId: number): string =>
		`/organization/${organizationId}/payments/tax-rates`,
};

export const Discounts = {
	Index: (organizationId: number): string =>
		`/organization/${organizationId}/payments/discounts`,
	Create: (organizationId: number): string =>
		`/organization/${organizationId}/payments/discounts/create`,
	Edit: (organizationId: number, id: number): string =>
		`/organization/${organizationId}/payments/discounts/${id}/edit`,
};

export const Refunds = {
	Index: (organizationId: number): string =>
		`/organization/${organizationId}/payments/refunds`,
};

export const Products = {
	Index: (organizationId: number): string =>
		`/organization/${organizationId}/payments/products`,
	Archived: (organizationId: number): string =>
		`/organization/${organizationId}/payments/products/archived`,
};

export const Product = {
	Create: (organizationId: number): string =>
		`/organization/${organizationId}/payments/products/new-product`,
	Edit: (organizationId: number, productId: number): string =>
		`/organization/${organizationId}/payments/products/edit/${productId}`,
	Show: (
		organizationId: number,
		productId: number,
		currentTab: string
	): string =>
		`/organization/${organizationId}/payments/products/${productId}/${currentTab}`,
};

export const Invoice = {
	New: (organizationId: number): string =>
		`/organization/${organizationId}/payments/new-order`,
};

export const Subscription = {
	Edit: (organizationId: number, id: number): string =>
		`/organization/${organizationId}/payments/subscription/${id}/edit`,
	Show: (organizationId: number, id: number, currentTab: string): string =>
		`/organization/${organizationId}/payments/subscription/${id}/${currentTab}`,
};

export const Order = {
	Show: (organizationId: number, id: number): string =>
		`/organization/${organizationId}/payments/order/${id}`,
};

export const Chat = {
	Index: (organizationId: number): string =>
		`/organization/${organizationId}/chat`,
	Monitored: {
		Index: (organizationId: number, targetAccountId: number): string =>
			`/organization/${organizationId}/chat/monitored/${targetAccountId}`,
		Show: (
			organizationId: number,
			targetAccountId: number,
			targetChatId: number
		): string =>
			`/organization/${organizationId}/chat/monitored/${targetAccountId}/${targetChatId}`,
	},
	New: (organizationId: number): string =>
		`/organization/${organizationId}/chat/new`,
	Show: (organizationId: number, targetChatId: number): string =>
		`/organization/${organizationId}/chat/${targetChatId}`,
};

export const Calendar = {
	Index: (
		organizationId: number,
		groupId: number,
		currentTab: string
	): string =>
		`/organization/${organizationId}/group/${groupId}/calendar/${currentTab}`,
};

export const User = {
	Profile: {
		Show: (
			organizationId: number,
			groupId: number,
			userId: number,
			currentTab: string
		): string =>
			`/organization/${organizationId}/group/${groupId}/user/${userId}/profile/${currentTab}`,
		Goals: (organizationId: number, groupId: number, userId: number): string =>
			`/organization/${organizationId}/group/${groupId}/user/${userId}/profile/development/goals`,
		PositionMatch: (
			organizationId: number,
			groupId: number,
			userId: number,
			ratingId: number
		): string =>
			`/organization/${organizationId}/group/${groupId}/user/${userId}/profile/development/position-match/${ratingId}`,
		PositionMatchCompare: (
			organizationId: number,
			groupId: number,
			userId: number,
			ratingId: number,
			compareId: number
		): string =>
			`/organization/${organizationId}/group/${groupId}/user/${userId}/profile/development/position-match/${ratingId}/compare/${compareId}`,
		PerformanceReviews: (
			organizationId: number,
			groupId: number,
			userId: number
		): string =>
			`/organization/${organizationId}/group/${groupId}/user/${userId}/profile/development/performance-reviews`,
	},
	Registrations: (organizationId: number, currentTab: string): string =>
		`/organization/${organizationId}/user/registrations/${currentTab}`,
	Submission: (organizationId: number, submissionId: number): string =>
		`/organization/${organizationId}/user/submissions/${submissionId}`,
	Settings: (organizationId: number, userId: number): string =>
		`/organization/${organizationId}/user/${userId}/settings`,
	Activity: {
		Show: (organizationId: number, activityId: number): string =>
			`/organization/${organizationId}/user-activity/${activityId}`,
	},
};

export const Registrations = {
	Category: (organizationId: number, categoryId: number): string =>
		`/organization/${organizationId}/registrations/category/${categoryId}`,
	Register: {
		Success: (
			organizationId: number,
			groupId: number,
			formId: number
		): string =>
			`/organization/${organizationId}/registrations/${groupId}/${formId}/success`,
		Cancel: (
			organizationId: number,
			groupId: number,
			formId: number,
			formSubmissionId: number
		): string =>
			`/organization/${organizationId}/registrations/${groupId}/${formId}/cancel/${formSubmissionId}`,
		PaymentStatus: (
			organizationId: number,
			groupId: number,
			formId: number
		): string =>
			`/organization/${organizationId}/registrations/${groupId}/${formId}/payment-status`,
	},
	Edit: (organizationId: number, groupId: number, formId: number): string =>
		`/organization/${organizationId}/registrations/${groupId}/edit/${formId}`,
	Duplicate: (
		organizationId: number,
		groupId: number,
		formId: number
	): string =>
		`/organization/${organizationId}/registrations/${groupId}/duplicate/${formId}`,
	Single: (
		organizationId: number,
		groupId: number,
		formId: number,
		currentTab: string
	): string =>
		`/organization/${organizationId}/registrations/${groupId}/${formId}/${currentTab}`,
	Create: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/registrations/${groupId}/create`,
	Index: (
		organizationId: number,
		groupId: number,
		currentTab: string
	): string =>
		`/organization/${organizationId}/registrations/${groupId}/${currentTab}`,
};

export const Form = {
	Submissions: {
		Single: (
			organizationId: number,
			groupId: number,
			formId: number,
			submissionId: number
		): string =>
			`/organization/${organizationId}/registrations/${groupId}/${formId}/submissions/${submissionId}`,
	},
};

export const Management = {
	Organization: (organizationId: number): string =>
		`/organization/${organizationId}/management/organization`,
	Overview: (organizationId: number): string =>
		`/organization/${organizationId}/management/overview`,
	SingleOrganization: (organizationId: number, singleGroupId: number): string =>
		`/organization/${organizationId}/management/group/${singleGroupId}`,
	Contacts: {
		Home: (organizationId: number): string =>
			`/organization/${organizationId}/management/contacts`,
	},
	Contact: {
		Create: (organizationId: number): string =>
			`/organization/${organizationId}/management/contacts/new-contact`,
		Edit: (organizationId: number, userId: number): string =>
			`/organization/${organizationId}/management/contacts/edit/${userId}`,
		Show: (
			organizationId: number,
			userId: number,
			currentTab: string
		): string =>
			`/organization/${organizationId}/management/contacts/${userId}/${currentTab}`,
	},
};

export const Sso = {
	Edit: (organizationId: number, providerId: number): string =>
		`/organization/${organizationId}/settings/sso/${providerId}/edit`,
	AttributeMapping: (organizationId: number, providerId: number): string =>
		`/organization/${organizationId}/settings/sso/${providerId}/attribute-mapping`,
};

export const Settings = {
	Users: (
		organizationId: number,
		groupId: number,
		currentTab: string
	): string =>
		`/organization/${organizationId}/settings/${groupId}/user-management/${currentTab}`,
	User: {
		Show: (organizationId: number, groupId: number, userId: number): string =>
			`/organization/${organizationId}/settings/${groupId}/user-management/user/${userId}`,
	},
	Organization: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/settings/${groupId}/organization`,
	Competitions: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/settings/${groupId}/competitions`,
	Index: (
		organizationId: number,
		groupId: number,
		currentTab: string
	): string =>
		`/organization/${organizationId}/settings/${groupId}/${currentTab}`,
};

export const Statistics = {
	AttendanceReport: {
		Show: (
			organizationId: number,
			groupId: number,
			currentTab: string
		): string =>
			`/organization/${organizationId}/statistics/${groupId}/attendance/${currentTab}`,
		User: (organizationId: number, groupId: number, userId: number): string =>
			`/organization/${organizationId}/statistics/${groupId}/user/${userId}`,
	},
	Match: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/statistics/${groupId}/match`,
	Training: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/statistics/${groupId}/training`,
};

export const Bookings = {
	Index: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/scheduling/${groupId}/bookings`,
	Print: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/scheduling/${groupId}/bookings/print`,
};

export const Templates = {
	Index: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/scheduling/${groupId}/schedule-templates`,
	Show: (organizationId: number, groupId: number, id: number): string =>
		`/organization/${organizationId}/scheduling/${groupId}/schedule-templates/${id}`,
	Print: (organizationId: number, groupId: number, id: number): string =>
		`/organization/${organizationId}/scheduling/${groupId}/schedule-templates/${id}/print`,
};

export const Resources = {
	Index: (
		organizationId: number,
		groupId: number,
		currentTab: string
	): string =>
		`/organization/${organizationId}/scheduling/${groupId}/resources/${currentTab}`,
};

export const TrainingLibrary = {
	Collection: {
		Show: (
			organizationId: number,
			groupId: number,
			collectionId: number
		): string =>
			`/organization/${organizationId}/development/${groupId}/training-library/collections/${collectionId}`,
	},
	Index: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/development/${groupId}/training-library`,
	Show: (
		organizationId: number,
		groupId: number,
		contentGroup: string
	): string =>
		`/organization/${organizationId}/development/${groupId}/training-library/${contentGroup}`,
};

export const VideoLibrary = {
	Collection: {
		Show: (
			organizationId: number,
			groupId: number,
			collectionId: number
		): string =>
			`/organization/${organizationId}/development/${groupId}/video-library/collections/${collectionId}`,
	},
	Index: (organizationId: number, groupId: number): string =>
		`/organization/${organizationId}/development/${groupId}/video-library`,
	Show: (
		organizationId: number,
		groupId: number,
		contentGroup: string
	): string =>
		`/organization/${organizationId}/development/${groupId}/video-library/${contentGroup}`,
};

export const Development = {
	Formations: {
		Index: (organizationId: number, groupId: number): string =>
			`/organization/${organizationId}/development/${groupId}/formations`,
	},
};

export const Event = {
	Create: (organizationId: number): string =>
		`/organization/${organizationId}/events/create`,
	Edit: (organizationId: number, eventId: number): string =>
		`/organization/${organizationId}/events/${eventId}/edit`,
	View: (organizationId: number, eventId: number, currentTab: string): string =>
		`/organization/${organizationId}/events/${eventId}/${currentTab}`,
};

export const Match = {
	View: (
		organizationId: number,
		eventId: number,
		matchId: number,
		currentTab: string
	): string =>
		`/organization/${organizationId}/events/${eventId}/matches/${matchId}/${currentTab}`,
	Edit: (
		organizationId: number,
		eventId: number,
		matchId: number,
		currentTab: string
	): string =>
		`/organization/${organizationId}/events/${eventId}/matches/${matchId}/edit/${currentTab}`,
};

export const EventSeries = {
	Edit: (organizationId: number, eventSeriesId: number): string =>
		`/organization/${organizationId}/event-series/${eventSeriesId}/edit`,
	View: (organizationId: number, eventSeriesId: number): string =>
		`/organization/${organizationId}/event-series/${eventSeriesId}`,
};

export const CustomLinks = {
	Show: (organizationId: number, title: string, linkId: number): string =>
		`/organization/${organizationId}/custom-links-${title}/${linkId}`,
};

export const Exercise = {
	Create: (organizationId: number): string =>
		`/organization/${organizationId}/exercises/new`,
	Edit: (organizationId: number, exerciseId: number): string =>
		`/organization/${organizationId}/exercises/${exerciseId}/edit`,
	Show: (organizationId: number, exerciseId: number): string =>
		`/organization/${organizationId}/exercises/${exerciseId}`,
};

export const TrainingSession = {
	Create: (organizationId: number): string =>
		`/organization/${organizationId}/training-sessions/create`,
	Show: (organizationId: number, sessionId: number): string =>
		`/organization/${organizationId}/training-sessions/${sessionId}`,
	Edit: (organizationId: number, sessionId: number): string =>
		`/organization/${organizationId}/training-sessions/${sessionId}/edit`,
	Print: (organizationId: number, sessionId: number): string =>
		`/organization/${organizationId}/training-sessions/${sessionId}/print`,
};

export const Video = {
	Show: (organizationId: number, videoId: number): string =>
		`/organization/${organizationId}/videos/${videoId}`,
	Analyze: (organizationId: number, videoId: number): string =>
		`/organization/${organizationId}/videos/${videoId}/analyze`,
};

export const VideoPlaylist = {
	Show: (organizationId: number, playlistId: number): string =>
		`/organization/${organizationId}/video-playlists/${playlistId}`,
};

export const Contact = {
	Fields: (organizationId: number, userId: number): string =>
		`/organization/${organizationId}/user/${userId}/properties`,
};

export const Registration = {
	Preview: (guid: string): string => `/registration/${guid}/preview`,
	Form: (guid: string): string => `/registration/${guid}/register`,
};

export const LoginRedirector = {
	Index: (): string => `/login`,
};

export const SsoRedirector = {
	Index: (): string => `/sso/redirect`,
};
