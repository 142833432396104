import { useT } from '@transifex/react';
import { Fragment } from 'react';

import spacing from 'pkg/config/spacing';

import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentOrganization } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { useCollection } from 'pkg/api/use_collection';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useQueryState } from 'pkg/hooks/query-state';

import Registration from 'routes/user/registrations/open/registration';

import Badge from 'components/Badge';
import BadgeWrapper from 'components/badge-wrapper';

import Row from 'components/layout/row';
import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';
import SearchInput from 'components/form/SearchInput';

import Divider from 'design/divider';

import * as css from './styles.css';

interface SingleCategoryFormsProps {
	categoryId: number;
}

export default function SingleCategoryForms({
	categoryId,
}: SingleCategoryFormsProps) {
	const t = useT();
	const org = useCurrentOrganization();
	const qs = useQueryState();
	const titleSearch = (qs.get('title') as string) || '';

	const { record, isLoading: isLoadingCategory } =
		useEndpoint<models.formCategory.FormCategory>(
			endpoints.FormCategories.Show(categoryId)
		);
	const { records, isLoading: isLoadingForms } =
		useCollection<models.form.Form>(
			endpoints.FormCategories.FormsIndex(categoryId),
			{
				queryParams: new URLSearchParams({
					title: titleSearch,
				}),
			}
		);

	const handleChange = (value: string) => {
		if (value.length > 0) {
			qs.set('title', value);
		} else {
			qs.remove('title');
		}

		qs.commit();
	};

	let content = (
		<Fragment>
			{records.map((r) => (
				<Registration key={r.id} form={r} group={org} />
			))}
		</Fragment>
	);

	if (!isLoadingCategory && isLoadingForms) {
		content = <Spinner />;
	}

	if (isLoadingCategory) {
		return <Spinner />;
	}

	return (
		<Column spacing={spacing._6}>
			<Row autoColumns="max-content" align="center">
				<BadgeWrapper>
					<Badge badgeUrl={org.profileImageUrl} />
				</BadgeWrapper>
				<span className={css.org}>{org.name}</span>
			</Row>
			<Divider />

			<div className={css.category}>{record.title}</div>
			<div>{t('Please select a registration below:')}</div>
			<SearchInput placeholder={t('Search')} onChange={handleChange} />
			{content}
		</Column>
	);
}
