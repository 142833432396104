// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.F3Zhfmd5TJtGMNp1gTCA {
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-lg);
	line-height: var(--font-line-height-lg);
}

.FDMVDCM0NX66HTcc68Jr {
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-3xl);
	line-height: var(--font-line-height-3xl);
}
`, "",{"version":3,"sources":["webpack://./routes/user/registrations/categories/styles.css"],"names":[],"mappings":"AAAA;CACC,wCAAwC;CACxC,8BAA8B;CAC9B,uCAAuC;AACxC;;AAEA;CACC,wCAAwC;CACxC,+BAA+B;CAC/B,wCAAwC;AACzC","sourcesContent":[".org {\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-lg);\n\tline-height: var(--font-line-height-lg);\n}\n\n.category {\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-3xl);\n\tline-height: var(--font-line-height-3xl);\n}\n"],"sourceRoot":""}]);
// Exports
export var org = `F3Zhfmd5TJtGMNp1gTCA`;
export var category = `FDMVDCM0NX66HTcc68Jr`;
export default ___CSS_LOADER_EXPORT___;
